module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Petra Mingneau","short_name":"Petra Mingneau","start_url":"/","background_color":"#ff006a","theme_color":"#ff006a","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a738ff0018f1c46c52f8e43ea6c9d57c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-172723402-1","head":true,"respectDNT":true,"anonymize":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-81CSFLB1VP"],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"petramingneau002","schemas":{"about":{"Main":{"title":{"type":"Text","config":{"label":"Title","useAsTitle":true}},"uid":{"type":"UID","config":{"label":"UID"}},"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}},"rich_text":{"type":"StructuredText","config":{"multi":"paragraph","label":"Rich Text"}}},"SEO":{"meta_title":{"type":"Text","config":{"label":"Meta Title"}},"meta_description":{"type":"Text","config":{"label":"Meta Description"}}}},"gallery":{"Main":{"title":{"type":"Text","config":{"label":"Title"}},"uid":{"type":"UID","config":{"label":"UID"}},"images":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}},"title1":{"type":"Text","config":{"label":"Title"}}},"label":"Images"}}},"SEO":{"meta_title":{"type":"Text","config":{"label":"Meta Title"}},"meta_description":{"type":"Text","config":{"label":"Meta Description"}}}},"global":{"Main":{"document_title":{"type":"Text","config":{"label":"Document Title","useAsTitle":true}},"primary_menu":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}},"title":{"type":"Text","config":{"label":"Title"}},"link":{"type":"Link","config":{"label":"Link"}}},"label":"Primary Menu"}},"footer_menu":{"type":"Group","config":{"fields":{"title":{"type":"Text","config":{"label":"Title"}},"link":{"type":"Link","config":{"label":"Link"}}},"label":"Footer Menu"}},"fourohfour_image":{"type":"Image","config":{"label":"404"}}},"SEO":{"site_meta_title":{"type":"Text","config":{"label":"Site Meta Title"}},"site_meta_description":{"type":"Text","config":{"label":"Site Meta Description"}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
